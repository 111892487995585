.CardColumns {
    display: flex;
    justify-content: flex-start;
    flex-flow: nowrap;
    width: 100%;

    /* Fills the space so that tap accessibility voiceover triggers */
/*    min-height: 100vh;*/

    /* Scrolling buffer considerations */
    padding-bottom: 50px;
}
